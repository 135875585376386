/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

import Slider from "@mui/material/Slider";

import breakpoints from "assets/theme/base/breakpoints";

function PlatformSettings() {
  const [followsMe, setFollowsMe] = useState(true);
  const [answersPost, setAnswersPost] = useState(false);
  const [mentionsMe, setMentionsMe] = useState(true);
  const [newLaunches, setNewLaunches] = useState(false);
  const [productUpdate, setProductUpdate] = useState(true);
  const [newsletter, setNewsletter] = useState(false);

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  function valuetext1(value) {
    return `${value}`;
  }

  function valuetext(value) {
    return `${value}`;
  }

  const minmin = 0.001;
  const maxmax = 2;
  const stepstep = 0.0001;
  const [buygasValue, setBuygasValue] = useState(minmin);
  const [sellgasValue, setSellgasValue] = useState(minmin);
  const [mevtipValue, setMevtipValue] = useState(minmin);
  const [slippageValue, setSlippageValue] = useState(10);

  const handleBuygasChange = (event, newValue) => {
    setBuygasValue(newValue);
  };

  const handleSellgasChange = (event, newValue) => {
    setSellgasValue(newValue);
  };

  const handleMevtipChange = (event, newValue) => {
    setMevtipValue(newValue);
  };

  const handleSlippageChange = (event, newValue) => {
    setSlippageValue(newValue);
  };

  return (
    <Card sx={{ boxShadow: "none" }}>
      <MDBox p={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          gas settings
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
        {/* <MDTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
          account
        </MDTypography> */}
        <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <MDBox width="25%" ml={0.5}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Buy Gas
            </MDTypography>
          </MDBox>
          <MDBox pr={1} width="20%">
            <MDInput
              // label="Buy Gas"
              value={buygasValue}
              // onChange={(e) => {
              //   setBuygasValue(Number(e.target.value));
              // }}
            />
          </MDBox>
          <MDBox width="10%" ml={0.5}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Sol
            </MDTypography>
          </MDBox>
          <MDBox pr={1} width="45%">
            <Slider
              value={buygasValue}
              // defaultValue={buygasValue}
              onChange={handleBuygasChange}
              getAriaValueText={valuetext1}
              step={stepstep}
              min={minmin}
              max={maxmax}
              valueLabelDisplay="auto"
              size="Default"
            />
          </MDBox>
        </MDBox>
        <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <MDBox width="25%" ml={0.5}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Sell Gas
            </MDTypography>
          </MDBox>
          <MDBox pr={1} width="20%">
            <MDInput
              // label="Buy Gas"
              value={sellgasValue}
              // onChange={(e) => {
              //   setSellgasValue(Number(e.target.value));
              // }}
            />
          </MDBox>
          <MDBox width="10%" ml={0.5}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Sol
            </MDTypography>
          </MDBox>
          <MDBox pr={1} width="45%">
            <Slider
              value={sellgasValue}
              // defaultValue={sellgasValue}
              onChange={handleSellgasChange}
              getAriaValueText={valuetext}
              step={stepstep}
              min={minmin}
              max={maxmax}
              valueLabelDisplay="auto"
              size="Default"
            />
          </MDBox>
        </MDBox>
        <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <MDBox width="25%" ml={0.5}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Mev Tip
            </MDTypography>
          </MDBox>
          <MDBox pr={1} width="20%">
            <MDInput
              // label="Buy Gas"
              value={mevtipValue}
              // onChange={(e) => {
              //   setMevtipValue(Number(e.target.value));
              // }}
            />
          </MDBox>
          <MDBox width="10%" ml={0.5}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Sol
            </MDTypography>
          </MDBox>
          <MDBox pr={1} width="45%">
            <Slider
              value={mevtipValue}
              // defaultValue={mevtipValue}
              onChange={handleMevtipChange}
              getAriaValueText={valuetext}
              step={stepstep}
              min={minmin}
              max={maxmax}
              valueLabelDisplay="auto"
              size="Default"
            />
          </MDBox>
        </MDBox>
        <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <MDBox width="25%" ml={0.5}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Slippage
            </MDTypography>
          </MDBox>
          <MDBox pr={1} width="20%">
            <MDInput
              // label="Buy Gas"
              value={slippageValue}
              // onChange={(e) => {
              //   setMevtipValue(Number(e.target.value));
              // }}
            />
          </MDBox>
          <MDBox width="10%" ml={0.5}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              %
            </MDTypography>
          </MDBox>
          <MDBox pr={1} width="45%">
            <Slider
              value={slippageValue}
              // defaultValue={mevtipValue}
              onChange={handleSlippageChange}
              getAriaValueText={valuetext}
              step={1}
              min={0}
              max={100}
              valueLabelDisplay="auto"
              size="Default"
            />
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default PlatformSettings;
